<template>
    <div class="page-content">
      <div class="block-content-wrapper">
        <div class="title-container" v-if="!isError">
          <div class="block-title">
            <span class="title-icon"></span>
            <h1>Technical Blog</h1>
          </div>
          <div class="search-container">
            <input type="text" id="search-input" name="search" @input="searchPosts" v-model="searchModel" placeholder="Search..."/>
         </div>
        </div>
        <pulse-loader class="spinner" :loading="!postsLoaded" :color="color" :size="size"></pulse-loader>
        <div class="blogs-list-container" id="search-result" v-if="postsLoaded && !isError">
          <div class="blog-card" v-for="(post,index) in posts" :key="post.slug + '_' + index">
              <div class="box">
                <article class="media">
                  <div class="image-container">
                    <figure class="image is-64x64">
                      <router-link class="read-more" :to="{ path: '/technical-blog/' + post.slug}">
                        <img v-if="post.feature_image" :src="post.feature_image" alt="">
                        <img v-else src="http://via.placeholder.com/250x250" alt="">
                      </router-link>
                    </figure>
                  </div>
                  <div class="media-content">
                    <div class="content">
                      <div class="content-info">
                        <h2 class="title is-5">{{ post.title }}</h2>
                        
                      </div>
                      <div class="read-more-container">
                        <span class="date">{{ post.customDate }}</span>
                        <router-link class="read-more" :to="{ path: '/technical-blog/' + post.slug}">
                          Read more
                        </router-link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
          </div>
        </div>
        <div class="no-content-block" v-if="posts.length === 0 && postsLoaded && !isError">
            It seems we can't find what you're looking for.
        </div>
        <div class="error-text" v-if="isError">
          <object :data="errorSvg" type="image/svg+xml">
          </object>
          <span>Something went wrong and posts can't be displayed</span><br/>
          <span>Try again after a while</span><br/>
          <span>Back to  
            <router-link class="link-back" :to="{ path: '/'}">
              Home
            </router-link>
          </span>
        </div>
      </div>
    </div>
</template>

<script>

import { searchinGhost } from '@/ghost-settings';
import { api } from '@/ghost-settings';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    name: 'BlogList',
    components: {
      PulseLoader
    },
    data() {
      return {
        page_title: 'Blog',
        searchModel: '',
        posts: [],
        postsLoaded: false,
        isError: false,
        errorSvg: "warning.svg",
        monthNames: ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ],
      }
    },
    mounted () {
      const svgPath = require.context(`@/assets/`, false);
      this.errorSvg = svgPath(`./${this.errorSvg}`);
    },
    methods: {
      getPosts() {
        this.postsLoaded = false;
        api.posts
          .browse({limit: 10})
          .then((posts) => {
            this.posts = [];
            posts.forEach(post => {
              let date = new Date(post.published_at);
              post.customDate = `${this.monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
              this.posts.push(post);
            });
            this.postsLoaded = true;

          })
          .catch(() => {
              this.isError = false;
          });
      },
      searchPosts() {
        this.postsLoaded = false;
        if (this.searchModel == "") {
          this.getPosts();
        }
        try {
          this.posts = searchinGhost.search(this.searchModel);
          this.posts.map(post => {
            let date = new Date(post.published_at);
            post['customDate'] = `${this.monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
            let res = post.url.split('/').slice(3).join("/");
            post['slug'] = res;
            return post;
          });
          this.postsLoaded = true;
        }
        catch {
          this.isError = false;
        }
      }
    },
    created() {
      this.getPosts();
    }
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.page-content {
    min-width: 280px;
    width: 100%;
    min-height: calc(100vh - 80px);
    background-image: $gradient;
    padding: 6rem 3rem;
    display: flex;
    justify-content: center;
    width: 100vw;
    box-sizing: border-box;
    .block-content-wrapper {
      max-width: 1200px;
      width: 100%;
    }
    h1, h2 {
      color: $light-main;
      margin: 0;
    }
    .block-title {
      display: flex;
      color: $light-main;
    }
    .title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6rem;
      h1 {
        text-align: start;
        font-size: 1.5em;
        text-transform: uppercase;
        letter-spacing: 3px;
        display: flex;
        font-weight: 100;
        span {
            display: inline-block;
        }
      }
      .search-container {
        display: flex;
        justify-content: flex-end;
        margin-left: 20px;
      }
      input {
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid $light-main;
        border-radius: 10px;
        height: 40px;
        outline: none;
        padding: 20px;
        box-sizing: border-box;
        color: $dark-white;
        font-size: 16px;
        min-width: 300px;
      }
      input::placeholder {
        color: $dark-white;
      }
    }
    .title-icon::before {
      width: 50px;
      display: block;
      content: "\1405";
      text-align: left;
      font-size: 1.5rem;
    }
    .blogs-list-container {
      display: flex;
      flex-wrap: wrap;
    }
    .blog-card {
      flex-grow: 0;
      width: calc(33% - 40px);
      margin-left: 40px;
      margin-bottom: 40px;
      min-width: 200px;
      background: #303030;
      border-radius: 30px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
      h2 {
        margin: 0;
        font-size: 1rem;
        color: $dark-white;
        text-decoration: none;
        font-weight: 100;
        text-align: left;
        text-transform: uppercase;
      }
      .image-container {
        height: 200px;
        .image {
          height: 100%;
          margin: 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
          border-radius: 30px 30px 0 0;
        }
      }
      .box, .media {
        height: 100%;
      }
      .media-content {
        padding: 20px;
        box-sizing: border-box;
        height: calc(100% - 200px);
        .content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }
        .content-info {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }
        .read-more-container {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
        }
        .read-more {
          text-transform: uppercase;
          color: $white;
          background: $light-main;
          font-size: 14px;
          padding: 7px;
          border-radius: 10px;
          display: block;
          width: 120px;
        }
        .date {
          font-size: 14px;
          color: grey;
          text-align: left;
          margin-bottom: 20px;
        }
      }
    }
    .no-content-block {
      color: $dark-white;
      font-size: 14px;
    }
}
.error-text {
  color: $dark-white;
  object {
    height: 200px;
    fill: $dark-white;
    display: flex;
    margin: auto;
    margin-bottom: 40px;
  }
}
.link-back {
  color: $light-main;
  margin-left: 5px;
}
object ::v-deep(.error-icon) {
  .error-icon {
    color: $light-main;
  }
}
.spinner {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 900px) {
  .page-content .blog-card {
    width: calc(50% - 40px);
  }
}
@media (max-width: 660px) {
  .page-content {
    padding: 3rem 1.5rem;
    .title-container {
      margin-bottom: 3rem;
      flex-direction: column;
      align-items: flex-start;
      .block-title {
        margin-bottom: 1.5rem;
      }
      .title-icon::before {
          display: none;
      }
      .search-container {
        margin-left: 0;
      }
    }
    .blog-card {
      width: calc(50% - 20px);
    }
    .blog-card:nth-child(odd) {
        margin-left: 0;
    }
  }
}
@media (max-width: 550px) {
  .page-content {
    .blog-card {
      width: 100%;
      margin-left: 0;
    }
    .title-container {
      .search-container {
        width: 100%;
      }
      input {
        min-width: 100%;
      }
    }
  }
}
</style>